export function useRobotsMeta(tag, maxImagePreview) {
    let imagePreview = '';
    if (typeof maxImagePreview === 'undefined') {
        imagePreview = ',max-image-preview:large';
    }

    useHead({
        meta: {
            name: 'robots',
            content: `${tag}${imagePreview}`,
        },
    });
}
